import { h, Component } from 'preact';

const ERROR_DISPLAY_MS = 10000;

class Feedback extends Component {
    // componentDidUpdate() {
    //     const { remove = () => {} } = this.props;
    //     clearTimeout(this.removeTimer);
    //     this.removeTimer = setTimeout(() => remove(), ERROR_DISPLAY_MS);
    // }

    // componentWillUnmount() {
    //     clearTimeout(this.removeTimer);
    // }

    render() {
        const { message, type = 'sencondary', icon, remove = () => {} } = this.props;

        if (!message) {
            return '';
        }
        return (<>
            {icon && <i class={`${icon} mr-2`} />}
            <span class={`text-${type}`}>
                {message}
            </span>
        </>);
    }
}

export default Feedback;
